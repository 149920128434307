/* eslint-disable */
import axios from "axios";

// Configurações padrão da API
const api = axios.create({
  baseURL: "http://localhost:4000/",
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export { api };