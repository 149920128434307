import React, { useRef, useState, useEffect } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { jsPDF } from "jspdf"; // Importa jsPDF

const Resum = ({ dados }) => {
  const url = dados.recordingUrl
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);

  const togglePlayPause = () => {
    if (audioRef.current.paused || currentTime === duration) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedMetadata = () => {
    setDuration(audioRef.current.duration);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handleSeek = (e) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  // Reseta o estado "isPlaying" para false quando o áudio termina
  useEffect(() => {
    if (currentTime === duration) {
      setIsPlaying(false);
    }
  }, [currentTime, duration]);

  // Função para realizar o download do arquivo .wav
  const handleDownload = () => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = url.split('/').pop(); // Nome do arquivo
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(err => console.error('Erro ao baixar o arquivo', err));
  };

  // Função para extrair texto recursivamente de nós React
  const extractText = (node) => {
    if (typeof node === 'string') {
      return node;
    } else if (Array.isArray(node)) {
      return node.map(extractText).join('\n');
    } else if (node?.props?.children) {
      return extractText(node.props.children);
    } else if (node?.props?.dangerouslySetInnerHTML) {
      return node.props.dangerouslySetInnerHTML.__html.replace(/<br\s*\/?>/g, '\n');
    }
    return '';
  };

  const handleDownloadPDF = () => {
    const textContent = formatText(dados.analysis.successEvaluation).props.children;
    let formattedText = extractText(textContent);

    const generateDynamicHTML = (textData) => {
      let dynamicHTML = `<div style="font-family: Arial, sans-serif; font-size: 18px; line-height: 1.5; background-color: #000000; color: #FFFFFF; padding: 0px; border-right: 5px solid #000000; border-bottom: 0px solid #000000;">`;

      dynamicHTML += `<h1 style="color: #ab96ff; text-align: center;">Análise da Ligação</h1>`;

      const lines = textData.split('\n').filter((line) => line.trim() !== '');
      const firstSection = lines.slice(1, 3);
      const remainingSections = lines.slice(3);

      dynamicHTML += `<div style="text-align: center; margin-bottom: 20px;">`;
      firstSection.forEach((line) => {
        dynamicHTML += `<p style="margin: 0; font-weight: bold; color: #FFFFFF;">${line}</p>`;
      });
      dynamicHTML += `</div>`;

      remainingSections.forEach((line) => {
        if (line.trim().startsWith('-')) {
          dynamicHTML += `<p style="margin-left: 20px; font-weight: bold; color: #FFFFFF;">${line}</p>`;
        } else {
          dynamicHTML += `<h2 style="margin-left: 20px; font-weight: bold; color: #ab96ff;">${line}</h2>`;
        }
      });
  
      dynamicHTML += `</div>`;
      return dynamicHTML;
    };

    // Função para carregar a imagem como base64
    const loadImage = (imagePath) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL();  // Converte a imagem para base64
          resolve(dataURL);
        };
        img.onerror = (error) => {
          console.error("Erro ao carregar a imagem: ", error);
          reject(error);
        };
        img.src = imagePath;
      });
    };

    loadImage('icon.png').then((imageBase64) => {
      const htmlContent = generateDynamicHTML(formattedText);

      const doc = new jsPDF();
      doc.setFillColor(0, 0, 0);
      doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');

      doc.addImage(imageBase64, 'PNG', 95, 5, 20, 0);  // Ajuste posição e tamanho conforme necessário

      doc.html(htmlContent, {
        callback: function (doc) {
          doc.save('relatorio-analise.pdf');
        },
        x: 10,
        y: 30,
        width: 190,
        windowWidth: 800,
      });
    }).catch((error) => {
      console.error("Erro ao carregar a imagem:", error);
    });
  };

  // Função para análise em formato de texto
  function formatText(text) {
    const sections = text.split(/(Pontos Positivos:|Pontos de Melhoria:|Sugestões de Melhoria:)/);

    const initialText = sections[0].trim();
    const formattedText = initialText.replace(/(\/10)/g, "$1<br />");

    const formatSection = (section) => {
      const lines = section.split('-').filter(line => line.trim() !== '').map(line => `- ${line.trim()}`);
      return lines.map((line, index) => <p key={index}>{line}</p>);
    };

    return (
      <div>
        <h2>Análise da Ligação</h2>
        <h4 dangerouslySetInnerHTML={{ __html: formattedText }} />
        {sections.map((section, index) => {
          if (section.includes("Pontos Positivos:")) {
            return (
              <div key={index}>
                <p2>Pontos Positivos:</p2>
                {formatSection(sections[index + 1])}
              </div>
            );
          }
          if (section.includes("Pontos de Melhoria:")) {
            return (
              <div key={index}>
                <p2>Pontos de Melhoria:</p2>
                {formatSection(sections[index + 1])}
              </div>
            );
          }
          if (section.includes("Sugestões de Melhoria:")) {
            return (
              <div key={index}>
                <p2>Sugestões de Melhoria:</p2>
                {formatSection(sections[index + 1])}
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  }

  return (
    <>
      <div>{formatText(dados.analysis.successEvaluation)}</div>
      <div style={styles.container}>
        <button onClick={togglePlayPause} style={styles.button}>
          {isPlaying && currentTime !== duration ? 'Pause' : '  Play  '}
        </button>
        <input
          type="range"
          min="0"
          step="0.001"
          max={duration}
          value={currentTime}
          onChange={handleSeek}
          style={styles.seekBar}
        />
        <span style={styles.time}>
          {formatTime(currentTime)} / {formatTime(duration)}
        </span>
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={handleVolumeChange}
          style={styles.volumeBar}
        />
        <button onClick={handleDownload} style={styles.downloadButton}>
          <i className="fas fa-download" style={styles.icon}></i>
        </button>
        <button onClick={handleDownloadPDF} style={styles.downloadButton}>
          <i className="fas fa-file-pdf" style={styles.icon}></i>
        </button>
        <audio
          ref={audioRef}
          src={url}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          style={styles.audio}
        />
      </div>
    </>
  );
};

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#a18def',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    padding: '10px',
    gap: '10px',
    width: '70%',
    margin: '20px auto',
  },
  button: {
    backgroundColor: '#f9f9f9',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '4px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    fontSize: '16px',
  },
  seekBar: {
    flex: 1,
    accentColor: '#f9f9f9',
  },
  time: {
    fontSize: '14px',
    color: '#555',
  },
  volumeBar: {
    width: '50px',
    accentColor: '#f9f9f9',
  },
  downloadButton: {
    backgroundColor: '#a18def',
    border: 'none',
    color: 'white',
    padding: '0px 5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  audio: {
    display: 'none',
  },
  icon: {
    marginRight: '8px',
    fontSize: '18px',
  },
};

export default Resum;
