import CallInterface from "./components/base/CallInterface";

const App = () => {
  return (
    <div>
      <CallInterface/>
    </div>
  );
};

export default App;
