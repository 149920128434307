import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = () => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    const fetchAnimation = async () => {
      const response = await fetch(
        'https://lottie.host/f7af59f6-daa9-4605-ab67-26ffc9e14a37/73alUpnBZe.json'
      );
      const data = await response.json();
      setAnimationData(data);
    };

    fetchAnimation();
  }, []);

  if (!animationData) {
    return <div>Loading...</div>; // Exibe "Loading..." até que os dados sejam carregados
  }

  // Defina as configurações da animação
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  return (
    <div style={{ width: '150px', height: '150px', paddingBottom: '16px', background: 'transparent' }}>
      <Lottie options={options} />
    </div>
  );
};

export default LottieAnimation;
