import React, { useEffect, useState } from "react";
import './App.css';
import Swal from "sweetalert2";
import Vapi from "@vapi-ai/web";
import { useStopwatch } from "react-timer-hook";
import MicLevel from "../call/MicLevel";
import LottieAnimation from "./LottieAnimation";
import Resum from "../call/Resum";
import { api } from "../../utils/api";

const vapi = new Vapi("592f36e6-1b9d-48cf-9c2e-871af6cd4a3d"); // Api Key

function CallInterface() {
  const [callId, setCallId] = useState("");
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const animationFrameIdRef = useState(null); // Armazena o ID do requestAnimationFrame

  const [volumeLevel, setVolumeLevel] = useState(0);
  const [isMuted, setMuted] = useState(false);

  const { seconds, minutes, start, pause, reset } = useStopwatch({ autoStart: false });
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);
      // resumiCall(callId)
    });

    vapi.on("error", (error) => {
      console.error(error);
      if (error.error.error === "Bad Request") {
        setConnecting(false);
        setCallActive(false);
        Swal.fire({
          icon: 'error',
          title: 'Chave de acesso incorreta',
          text: 'Ocorreu um erro ao iniciar a chamada.',
          confirmButtonText: 'OK'
        });
      }
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });
  }, []);

  useEffect(() => {
    if (connecting) {
      reset(); // Reseta o cronômetro para zero
      start(); // Inicia o cronômetro
    } else if (!connecting && !connected) {
      pause(); // Pausa o cronômetro quando desconectado
    }
  }, [connected, connecting, start, pause, reset]);

  const startCallInline = async () => { // Ligar novamente
    setConnecting(true);
    setConnected(false);

    try {
      const response = await vapi.start(accessCode);

      if (response && response.id) {
        setCallId(response.id);
      } else {
        throw new Error("Resposta inválida");
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao iniciar a chamada',
        text: error.message || 'Ocorreu um erro inesperado.',
        confirmButtonText: 'OK'
      });
    }
  };

  const toggleMute = () => {
    if (vapi.call) { // Verifica se há um objeto 'call' ativo
      const newMutedState = !isMuted;
      vapi.setMuted(newMutedState); // Define o estado do microfone no Vapi
      setMuted(newMutedState); // Atualiza o estado local
    } else {
      console.error("Nenhuma chamada ativa para mutar.");
    }
  };

  const endCall = () => {
    vapi.stop();
    setAccessValid(true);
    setConnecting(false);
    setConnected(false);
    cancelAnimationFrame(animationFrameIdRef.current); // Cancela a requisição de animação
    resumiCall(callId)
  };

  const [isHelpDialogVisible, setHelpDialogVisible] = useState(false);
  const [isCallActive, setCallActive] = useState(false);
  const [accessCode, setAccessCode] = useState('');
  const [isAccessValid, setAccessValid] = useState(true);
  const [credit, setCredit] = useState('');

  const toggleHelpDialog = () => setHelpDialogVisible(!isHelpDialogVisible);

  const startConversation = async () => { // Nova ligação
    setAccessValid(true);
    setCallActive(true); // Atualiza o estado para exibir a interface da chamada
    setConnecting(true); // Muda o estado para indicar que a conexão está sendo feita

    try {
      const response = await vapi.start(accessCode); // Verifique se vapi.start retorna uma Promise

      if (response && response.id) {
        setCallId(response.id);
        // creditTotal()
      } else {
        throw new Error("Resposta inválida ou sem ID");
      }
    } catch (error) {
      console.error("Erro ao iniciar a chamada:", error);

      Swal.fire({
        icon: 'error',
        title: 'Erro ao iniciar a chamada',
        text: error.message || 'Ocorreu um erro inesperado.',
        confirmButtonText: 'OK'
      });
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [callData, setCallData] = useState(null);

  async function resumiCall(callId, maxAttempts = 5, interval = 2500) {
    setIsModalLoading(true);
    try {
      await new Promise(resolve => setTimeout(resolve, interval));

      const response = await fetch(`https://api.vapi.ai/call/${callId}`, {
        method: "GET",
        headers: {
          "Authorization": "Bearer e73567d2-2a98-4318-8f57-fb623764d9b5"
        },
      });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`);
      }

      const data = await response.json();

      if (data.status === "in-progress" && maxAttempts > 0) {
        console.log("Chamada em andamento, tentando novamente...");
        // Chama a função novamente, reduzindo o número de tentativas disponíveis
        return resumiCall(callId, maxAttempts - 1, interval);
      }

      if (data.status === "ended") {
        setIsModalLoading(false);
        console.log("Chamada concluída!");
        setCallData(data);
        setIsModalVisible(true);
        // creditTotal(data)
      } else if (maxAttempts === 0) {
        setIsModalLoading(false);
        console.warn("Número máximo de tentativas atingido. A chamada ainda está em andamento.");
      }
  
    } catch (error) {
      console.error("Erro ao buscar dados da chamada:", error);
    }
  }

  async function creditTotal(callData) {
    console.log(callData)
    try {
      const processCallData = (callData) => {
        const { startedAt, endedAt } = callData;

        const start = new Date(startedAt);
        const end = new Date(endedAt);
        const differenceInMs = end - start;

        const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
        const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

        const creditSpending = `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return {
          id_assistent: accessCode,
          creditSpending,
        };
      };
      const result = processCallData(callData);
      console.log(result);

      const token = "123e4567-e89b-12d3-a456-426614174000";
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`
      const response = await api.put(`/companies/`, result);
      
      console.log(response)

      // if (!response.ok) {
      //   throw new Error(`Erro na requisição: ${response.statusText}`);
      // }
      // const data = await response.json();

      // Soma do custo total
      // const totalCost = data.reduce((sum, call) => {
      //   const costBreakdownTotal = call.costBreakdown?.total || 0;
      //   return sum + costBreakdownTotal;
      // }, 0);

      // setCredit(totalCost.toFixed(2))
      // Retorna apenas o valor total
      // console.log(totalCost.toFixed(4));
    } catch (error) {
      console.error("Erro ao buscar dados da chamada:", error);
    }
  }

  // Formatar o tempo em minutos e segundos
  const formatTime = () => `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  const closeErrorModal = () => setShowErrorModal(false);

  return (
    <div className="call-container">
      {showErrorModal && (
        <div className="error-modal">
          <div className="error-modal-content">
            <h2>Ocorreu um erro!</h2>
            <p>Não foi possível iniciar a chamada. Por favor, tente novamente.</p>
            <button onClick={closeErrorModal}>Fechar</button>
          </div>
        </div>
      )}
      <button className="help-button" onClick={toggleHelpDialog}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <circle cx="12" cy="12" r="10" />
          <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fontSize="10px" fill="currentColor">?</text>
        </svg>
      </button>
      {!isCallActive ? (
        <div className="start-screen" id="startScreen">
          <LottieAnimation/>
          <h1 className="welcome-text">Bem-vindo ao Assistente IA</h1>
          <p className="description">
            Pronto para iniciar uma conversa com seu assistente de IA? Digite seu código de acesso para começar.
          </p>

          <div className="access-code-section">
            <label className="access-code-label" htmlFor="startAccessCode">Código de Acesso</label>
            <input
              type="text"
              id="startAccessCode"
              className="access-code-input"
              placeholder="Digite seu código"
              maxLength="40"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)} // Atualiza o valor do código conforme o usuário digita
            />
            {!isAccessValid && (
              <div id="accessError" className="access-error">
                Código de acesso incorreto, entre em contato com nossa equipe
              </div>
            )}
          </div>

          <button
            className={`start-button ${accessCode.trim() ? 'active' : ''}`} // Habilita o botão somente se o código não estiver vazio
            onClick={startConversation} // Chama a função startConversation ao clicar
            disabled={!accessCode.trim()} // Desabilita o botão caso o código esteja vazio
          >
            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M12 16l4-4-4-4M8 12h8" />
            </svg>
            Iniciar Conversa
          </button>
        </div>
      ) : (
        <div className="call-interface full-width">
          <div className="call-header full-width">
          {/* <div className="call-status">Creditos {credit}</div> */}
            <div className="avatar">
              <img
                src="https://d3v0px0pttie1i.cloudfront.net/uploads/user/avatar/39300491/48c0dd3a.png"
                alt="IA Logo"
                className="avatar-img"
              />
            </div>
            <div className="call-status">Assistente IA</div>
            <div className="call-timer">{formatTime()}</div>
          </div>

          {connecting && (
            <>
              <div className="wave-animation">
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="wave-bar"></div>
                ))}
              </div>
              <div className="status-text">Iniciando...</div>
            </>
          )}
          {!connecting && !connected && (
            <div className="status-text">Desconectado</div>
          )}
          {connected && (
            <div className="status-text">
              {isMuted ? "Microfone desativado" : "Ouvindo..."}
            </div>
          )}

          <div className="call-controls">
            {connected && (
              <>
                <button className={`control-button mute ${isMuted ? "active" : ""}`} onClick={toggleMute}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    {isMuted ? (
                      <>
                        <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z" />
                        <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z" />
                        <line x1="4" y1="4" x2="20" y2="20" stroke="currentColor" strokeWidth="2" />
                      </>
                    ) : (
                      <>
                        <path d="M12 14c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3z" />
                        <path d="M17 11c0 2.76-2.24 5-5 5s-5-2.24-5-5H5c0 3.53 2.61 6.43 6 6.92V21h2v-3.08c3.39-.49 6-3.39 6-6.92h-2z" />
                      </>
                    )}
                  </svg>
                </button>
                <button className="control-button end-call" onClick={endCall}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29L.29 13.08c-.18-.17-.29-.42-.29-.7 0-.28.11-.53.29-.71C3.34 8.78 7.46 7 12 7s8.66 1.78 11.71 4.67c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28-.79-.73-1.68-1.36-2.66-1.85-.33-.16-.56-.5-.56-.9v-3.1C15.15 9.25 13.6 9 12 9z" />
                  </svg>
                </button>
              </>
            )}
            {!connected && !connecting && (
              <button className="control-button call-again" onClick={startCallInline}>
                Ligar novamente
              </button>
            )}
          </div>
          {/* <MicLevel volume={volumeLevel} /> */}
        </div>
      )}

      {isModalLoading && (
        <div className="help-dialog-loading active">
          <LottieAnimation/>
          <div className="help-content-loading">
            <p>
              Gerando análise da ligação...
            </p>
          </div>
        </div>
      )}

      {isModalVisible && (
        <div className="help-dialog-resum active">
          <div className="help-content-resum">
            <Resum dados={callData} />
            <div className="help-buttons">
              <button onClick={() => setIsModalVisible(false)} className="help-close-button">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}

      {isHelpDialogVisible && (
        <div className="help-dialog active">
          <div className="help-content">
            <h2>Sobre a PitchYes IA</h2>
            <p>
              Treine sua equipe mais rápido com inteligência artificial de voz. Nossa solução permite que você crie assistentes
              virtuais personalizados para treinar e capacitar sua equipe de forma eficiente.
            </p>
            <div className="help-buttons">
              <button
                onClick={() => window.open('https://pitchyes.com.br', '_blank')}
                className="help-site-button"
              >
                Visite nosso site
              </button>
              <button onClick={toggleHelpDialog} className="help-close-button">
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CallInterface;
